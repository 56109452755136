@font-face {
    font-family: 'Coolvetica';
    src: url('../../assets/fonts/coolvetica-rg.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Coolvetica';
    src: url('../../assets/fonts/coolveticael-extra-light.woff') format('woff');
    font-weight: normal;
}

:root {
    --official-blue: #08B2E3;
    --official-hover-blue: #0578A9;
}

.map-videojsplayer {
    position: relative;
}
  
.map-closebutton, .map-leftbutton, .map-rightbutton {
    position: absolute; /* Absolute positioning */
    color: #E5E5E5;
    cursor: pointer;
    z-index: 100;  /* High z-index to make it appear above the video player */
}
  
.map-closebutton {
    top: 5px;            /* Place it at the top */
    right: 5px;          /* Place it at the right */
}
  
.map-leftbutton {
    top: 50%;            /* Center vertically */
    left: 5px;           /* Place it at the left */
    transform: translateY(-50%);  /* Adjust for true centering */
}
  
.map-rightbutton {
    top: 50%;            /* Center vertically */
    right: 5px;          /* Place it at the right */
    transform: translateY(-50%);  /* Adjust for true centering */
}

.map-container {
    position: relative;
}


.map-StartStreamButton {
    background-color: var(--official-blue);
    position: absolute;
    top: 10px;
    right: -50px;
    z-index: 2;
    transform: translate(-50%, 0%);
    transition: background-color 0.2s;
    padding: 6px 12px;
    /* Padding around the text in the button. */
    border-radius: 8px;
    /* Rounded corners on the button. */
    border: none;
    /* No border on the button. */
    cursor: pointer;
    /* The cursor will look like a pointer when over the button. */
    font-size: 16px;
    /* Size of the text in the button. */
    font-weight: 600;
    /* Thickness of the text in the button. */
    color: #fff;
    /* Color of the text in the button. */
    font-family: 'Coolvetica', fallback, sans-serif;
    letter-spacing: 1px;
  
}

.map-StartStreamButton:hover {
    background-color: var(--official-hover-blue);
 }

.map-refreshStreamButton {
    position: absolute;
    top: 10px;
    right: 150px;
    z-index: 2;
}

.map-refreshStreamButton {
    background-color: var(--official-blue);
    color: white;
    border: none;
    padding: 6px 15px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 30px;
    font-family: 'Coolvetica', fallback, sans-serif;
    font-weight: bold;
    transition: background-color 0.2s;
}

.map-refreshStreamButton:hover {
    background-color: var(--official-hover-blue);
}

sup {
    font-size: 50%;   /* Makes the superscript text smaller */
}

.tooltip {
    position: absolute;
    text-align: center;
    bottom: 32px; /* Adjust this value to position above the VideoJS progress bar */
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.603); /* Dark grey with 80% opacity */
    color: white;    
    padding: 5px;
    z-index: 100;
    transition: opacity 0.6s ease-in-out;
    opacity: 1; /* Full visibility by default */
}

.fade-out {
    opacity: 0; /* Fully transparent */
}

.map-MenuButton {
    background-color: white; /* Change the background-color to white */
    position: absolute;
    top: 8px;
    left: 45px;
    z-index: 2;
    transform: translate(-50%, 0%);
    transition: background-color 0.2s;
    border: none; /* Remove any border */
    border-radius: 50%; /* Make the button circular */
    width: 55px; /* Set a fixed width (adjust as needed) */
    height: 55px; /* Set a fixed height (adjust as needed) */
    display: flex; /* Center the icon */
    justify-content: center; /* Center the icon horizontally */
    align-items: center; /* Center the icon vertically */
}

.map-MenuButton:hover {
    background-color: grey;
 }


:root {
    --calculated-width: calc(100vw); /* Remove the margin subtraction to take full width */
}

.video-js {
    width: 845px;
    height: 480px;
}

.video-player-container {
    position: absolute;
    top: 70px;
    left: 0;
}



.map-titlecontainer,
.map-helpText {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    padding: 1rem;
    border-radius: 4px;
}

.map-titlecontainer {
    top: -6px;
    /* left: 10rem; */
    color: #001308;
    font-size: 40px;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0);
}

.map-helpText {
    top: 5rem;
    color: #000000;
    background-color: rgba(255, 255, 255, 1.0);
    font-size: 15px;
    font-family: 'Coolvetica', fallback, sans-serif;
    font-style: italic;
    font-weight: bold;
    padding: 0.25rem;
    text-align: center;
}

.highlight {
    color: #E85D4A;
}

.menu-container {
    position: absolute;
    top: 63px;
    left: 20px;
    background-color: white;
    border: 5px solid black; /* Corrected this line */
    border-radius: 8px;
    z-index: 900; /* Above other elements */
}

.menu-option {
    display: block;
    padding: 10px 20px;
    text-align: left;
    cursor: pointer;
    background-color: #f7f7f7;
    transition: background-color 0.3s;
    font-family: 'Coolvetica', fallback, sans-serif;
    font-weight: bold;
    font-size: 14px;
    border: none;         /* Ensure no border */
    outline: none;        /* Ensure no outline */
    width: 170px;  
}


.menu-option:hover {
    background-color: #e0e0e0;
}


.map-top-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;  /* You can adjust this value as per your requirement */
    background-color: white;
    z-index: 1;  /* Making sure it's behind buttons and titles */
}

/* By default, the span doesn't break onto a new line */
.responsive-break::before {
    content: " ";
}


/* Below 865 pixels the refresh button doesn't fit on the screen any longer*/
@media (max-width: 700px) {
    .map-refreshStreamButton {
        display: none;
    }
}

/* Below 550 pixels remove the startStream button */
@media (max-width: 550px) {
    .map-StartStreamButton {
        display: none;
    }
}

/* Below 400 pixels reduce the size of the logo, the hamburger menu, and the top bar */
@media (max-width: 400px) {
    .map-titlecontainer {
        font-size: 32px;
    }
    .map-top-bar {
        height: 63px;
    }
    .map-MenuButton {
        width: 45px;
        height: 45px;
    }
}

/* timer css */
.map-timercontainer {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, 0%);
    transition: background-color 0.2s;
}