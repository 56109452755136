.timer-container {
    background-color: lightgray;
    border-radius: 12px;
    padding: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    vertical-align: center;
    transition: all 0.3s ease-out;
    background-image: linear-gradient(to bottom right, #eef2f3 0%, #d2dce3 100%);
    overflow: hidden;
  }

.timer-container-history {
  height: 200px;
}

  /* Standard */
  @keyframes pulsate {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }

  /* Webkit browsers like Safari */
  @-webkit-keyframes pulsate {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }

  .pulsate {
    animation: pulsate 1.5s ease-in-out infinite;
    -webkit-animation: pulsate 1.5s ease-in-out infinite; /* For Safari */
  }

  .toggle-button {
    position: relative;
    width: 50px;
    height: 50px;
  }
  
  .icon {
    position: absolute;
    top: 5px;
    left: 5px;
    transition: opacity 0.5s ease-in-out;
    cursor: pointer;
    opacity: 0;
    width: 40px;
    height: 40px;
  }
  
  .icon--active {
    opacity: 1;
  }
  
  .icon--inactive {
    opacity: 0;
  }


  
  
  .date-time-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease width 0.3s ease height 0.3s ease;
  }
  
  .date-time-container.active {
    opacity: 1;
    transition: opacity 2.0s ease /*, slower fadein*/;
  }

  .date-time-container.inactive {
    width: 0px;
    height: 0px;
  }
  
  .date-time {
    cursor: pointer;
  }
  

  .picker {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .picker__controls {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .picker__controls button {
    font-size: 18px;
    color: #888;
    background: transparent;
    border: none;
    cursor: pointer;
  }


  .picker__controls__button:hover {
    background-color: #f1f1f1;
  }
  
  .picker__value {
    margin: 0 15px;
    font-size: 18px;
    font-weight: bold;
  }
  

  .dateTimePicker {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .dateTimePicker__date,
  .dateTimePicker__time {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  .slider {
    width: 100%;
    appearance: none;
    height: 8px;
    border-radius: 4px;
    background: #ddd;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    background: #007bff;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #007bff;
    cursor: pointer;
    border-radius: 50%;
  }