/* These are styles scoped to the App component, which is essentially the same as a global styles.*/
/* Often-reused styles for buttons,  */


:root {
  --vh: 100vh;
  /* Fallback for browsers that do not support CSS variables */
  --CSblack: #001308;
  --CSred: #E85D4A;
  --CSblue: #08B2E3;
  --CSwhite: #F5F5F5;
  --border-radius: 10px;
  --controls-transition-duration: 0.3s;
}

.App {
  width: 100vw;
  /* Width of .App set to 100% of viewport width. */
  height: 100svh;
  /* Height of .App set to 100% of SMALL viewport height. */
  display: grid;
  /* Using CSS Grid for layout inside .App. */
  row-gap: 0px;
  /* No gap between the rows in the grid. */
  padding: 0px;
  /* No padding around the .App element. */
  place-items: center;
  /* Centers grid items both vertically and horizontally. */
  background-color: #ffffff;
  /* Sets background color of .App to white. */
}

.row {
  display: flex;
  /* Using Flexbox for layout inside .row. */
  flex-direction: row;
  /* Elements in the .row are aligned in a row. */
  justify-content: space-between;
  /* Space distributed evenly between flex items. */
  align-items: center;
  /* Centers items vertically if the row doesn't take up the full height. */
}

.button {
  padding: 10px 20px;
  /* Padding around the text in the button. */
  margin: 10px;
  /* Space around the button. */
  border-radius: 8px;
  /* Rounded corners on the button. */
  border: none;
  /* No border on the button. */
  background-color: #0197F6;
  /* Background color of the button. */
  cursor: pointer;
  /* The cursor will look like a pointer when over the button. */
  font-size: 14px;
  /* Size of the text in the button. */
  font-weight: 600;
  /* Thickness of the text in the button. */
  color: #fff;
  /* Color of the text in the button. */
  font-family: 'Coolvetica', fallback, sans-serif;
  letter-spacing: 1px;
  font-size: 20px;
}

.button:hover {
  background-color: #2980b9;
}

.button:focus {
  outline: none;
}

.button:active {
  background-color: #1f618d;
}

.red {
  background-color: #F44336;
  /* Overrides the background color for elements also with the class 'red'. */
}

.red:hover {
  background-color: #E91B0C;
  /* Overrides the background color for elements also with the class 'red'. */
}

.red:active {
  background-color: #C2160A;
  /* Overrides the background color for elements also with the class 'red'. */
}


.button:disabled {
  background-color: #cccccc;
  /* The background color of the button when it is disabled. */
  cursor: not-allowed;
  /* The cursor will look like 'not-allowed' when over the disabled button. */
}


/* Changes padding around .App for screens that are at least 768px wide. (can change if you want it to look different on Desktop) */
@media screen and (min-width: 768px) {
  .App {
    padding: 0px 0px;
    height: 100vh;
    /* Changed 100vh to var(--vh) */
  }
}

@font-face {
  font-family: 'Coolvetica';
  src: url('assets/fonts/coolvetica-rg.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

.CSFont {
  font-family: 'Coolvetica', fallback, sans-serif;
}

.CSBlack {
  color: var(--CSblack);
}


.CSRed {
  color: var(--CSred);
}