.streamerplayer-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

}

.streamerplayer-rows {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
}

.streamerplayer-rows-bottom {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 4px;
  font-size: 18px;
  text-align: center;
  z-index: 999; /* Ensure the message is on top of other content */
  max-width: 80%; /* Limit the width of the message */
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

/* Styling for the overlaid title/logo */
.streamerplayer-title {
  font-size: 4rem;
  font-weight: bold;
}

.streamerplayer-backButton {
  padding: 10px;
}

.streamerplayer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  height: 60vh;
  width: 80vw;
  background-color: #000;
}

.player-wrapper {
  position: relative;
  width: 100%;
  border-radius: 25px;
  height: 100%;
  overflow: hidden;
}

.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

video {
  width: 100%;
  height: auto;
  object-fit: contain;
}

/* Add these styles for the dropdown menu */
.event-id-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px; /* Add margin for spacing */
}

/* Style the select element */
#eventIdSelect {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  appearance: arrow; /* Remove default styles (e.g., arrow) */
  cursor: pointer; /* Show pointer cursor on hover */
}

/* Style the options within the select */
#eventIdSelect option {
  font-size: 16px;
  background-color: #fff;
  color: #333;
}

/* Style the dropdown arrow (you may need to use a custom arrow icon) */
#eventIdSelect::-ms-expand {
  display: none; /* Hide default arrow in IE */
}

/* Add a border and box shadow on focus */
#eventIdSelect:focus {
  outline: none;
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a subtle box shadow on focus */
}


.broadcasting-dot {
  position: absolute;
  z-index: 10;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 25px;
  height: 25px;
  border: 2px solid #000;
  background-color: red;
  border-radius: 50%;
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}